.main {
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}
.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  direction: rtl;
}
h1 {
  font-size: 3rem;
}

h2 {
  text-align: center;
  font-size: 2rem;
  padding: 0 20px;
  direction: rtl;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
h2.with-icon {
  flex-direction: row;
}
.next {
  margin-top: 50px;
  text-align: center;
}
.next button {
  padding: 10px 60px;
  font-size: 1.5rem;
  color: white;
  border: none;
  border-radius: 5px;
  background-color: #4caf50;
  cursor: pointer;
}

@media (max-width: 450px) {
  h2 {
    font-size: 1.3rem;
    padding: 0 10px;
  }
}
