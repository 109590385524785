.select-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}
.select-wrapper .select-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  direction: rtl;
}
.select-wrapper .select-container .title {
  font-size: 1.5rem;
}
.submit {
  margin-top: 50px;
}
.submit button {
  padding: 10px 60px;
  font-size: 1.5rem;
  color: white;
  border: none;
  border-radius: 5px;
}
.submit .submit-bag {
  background-color: #4caf50;
  cursor: pointer;
}
.submit .dont-submit {
  background-color: #c4bcbc;
}
.error {
  color: red;
  font-size: 1.5rem;
  margin-top: 30px;
}
@media (max-width: 450px) {
  h1 {
    font-size: 2rem;
  }
  .select-wrapper .select-container .title {
    font-size: 1.2rem;
  }
}
